<template>
    <div>
        <PageHeader></PageHeader>
        <div class="page-content d-flex">
            <div class="contact-form--1 mr-0 mr-md-5">
                <div class="section-title text-left mb--50">
                    <h2 class="title">{{$store.state.language.contactUs.title}}</h2>
                    <p class="description">{{$store.state.language.contactUs.desc}}</p>
                </div>
                <div class="form-wrapper">
                    <div>
                        <label for="item01">
                            <input type="text" name="name" id="item01" :placeholder="$store.state.language.contactUs.name" value="" v-model.trim="$v.name.$model" :class="$store.getters.getValidationClass($v.name)">
                        </label>
                        <label for="item02">
                            <input type="text" name="email" id="item02" :placeholder="$store.state.language.contactUs.mail" value="" v-model.trim="$v.email.$model" :class="$store.getters.getValidationClass($v.email)">
                        </label>
                        <label for="item03">
                            <input type="text" name="subject" id="item03" :placeholder="$store.state.language.contactUs.subject" value="" v-model.trim="$v.subject.$model" :class="$store.getters.getValidationClass($v.subject)">
                        </label>
                        <label for="item04">
                            <textarea maxlength="400" type="text" id="item04" name="message" :placeholder="$store.state.language.contactUs.message" spellcheck="false" v-model.trim="$v.message.$model" :class="$store.getters.getValidationClass($v.message)">
                            </textarea>
                        </label>
                        <vue-recaptcha ref="recaptcha" @expired="resetCaptcha" @verify="onVerify" class="my-3 mt-0 recaptchaDiv" :loadRecaptchaScript="true" sitekey="6LeWTt4ZAAAAAHRAH9cCAcALkKhiWhzgD-OHFeqB">
                        </vue-recaptcha>
                        <button style="outline: none" @click.stop="sendMail()" class="rn-button-style--2 btn-solid cursor-pointer hover-opacity" id="mc-embedded-subscribe">
                            <span v-if="!loading">{{$store.state.language.contactUs.submit}}</span>
                            <div v-else class="spin-animation">
                                <i  class="mdi mdi-loading"></i>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="$store.state.window.pageWidth < 960 ? false : true">
                <div class="thumbnail mb_md--30 mb_sm--30">
                    <img class="mw-100" :src="require('@/assets/img/contact-image.jpg')" alt="LPS">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PageHeader from '@/components/PageHeader'
import $ from 'jquery'
import VueRecaptcha from 'vue-recaptcha';
import { required , minLength, maxLength, email, sameAs, not} from 'vuelidate/lib/validators'
export default {
    name: 'Contact',
    data: () => {
        return {
            loading: false,
            email: '',
            name: '',
            subject: '',
            message: '',
            captcha: ''
        }
    },
    validations: {
        name: { required },
        email: { required, email },
        subject: { required },
        message: {required }
    },
    components: {
        PageHeader,
        VueRecaptcha
    },
    methods: {
        sendMail: function(){
            this.$v.$touch();
            
            if(!(this.$v.$invalid)){
                this.loading = true;
                $.ajax({
                    url: "https://leanpowersolutions.com/php-mail/Mail_Test.php",
                    type: "POST",
                    data: {
                        'senderName': this.name,
                        'senderEmail': this.email,
                        'senderSubject': this.subject,
                        'senderMessage': this.message,
                        'captcha': this.captcha
                    },
                    success: (res) => {
                        console.log(res);
                        if(res === 'CAPTCHA_ERROR'){
                            this.$notify({
                                title: 'Error!',
                                type: 'error',
                                text: 'Please verify the captcha.'
                            });
                        }else if(res === 'OK'){
                            this.$notify({
                                title: 'Mail sent succesfully!',
                                type: 'success',
                                text: 'We will contact you as soon as possible.'
                            });
                            setTimeout(() => {
                                window.location.reload()
                            }, 1000);
                        }else{
                            this.$notify({
                                title: 'Error!',
                                type: 'warn',
                                text: 'Something went wrong.'
                            });
                        }
                        this.loading = false;
                    },
                    error: (er) => {
                        console.log(er);
                        this.$notify({
                            title: 'Error!',
                            type: 'warn',
                            text: 'Something went wrong.'
                        });
                        this.loading = false;
                    }
                });
            }
            else{
                this.$notify({
                    title: 'Form is not valid.',
                    type: 'warn',
                    text: 'Please fill the form correctly'
                });
            }
            return;
        },
        onVerify: function(response){
            this.captcha = response;
        },
        resetCaptcha: function() {
            this.$refs.recaptcha.reset();
            this.captcha = ''
        },
    }
}
</script>
<style>
    .page-content{
        max-width: 1300px;
        margin: 100px auto;
        padding: 0px 25px;
    }

    figure, form {
        margin: 0;
    }
    /* ------**/
    .contact-form--1 {
        overflow: hidden;
        position: relative;
    }
    .contact-form--1 form{
        overflow: hidden;
        display: block;
        position: relative;
    }
    /** ----- */
    .contact-form--1 label {
        display: block;
        margin-bottom: 0;
    }
    .contact-form--1 input, .contact-form--1 textarea {
        display: block;
        width: 90%;
        padding: 0 20px;
        border: 3px solid rgba(0,0,0,.1);
        border-radius: 5px;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
        height: 50px;
        line-height: 46px;
        margin-bottom: 20px;
        outline: none;
        color: #1f1f25;
        font-size: 15px;
        letter-spacing: .1px;
        font-family: Poppins,sans-serif !important;
    }
    .contact-form--1 input.is-invalid, .contact-form--1 textarea.is-invalid {
        border-color: #F44336;
    }

    
    .contact-form--1 textarea {
        height: 120px;
    }

    a.rn-button-style--2, button.rn-button-style--2 {
        color: #c6c9d8;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 2px;
        border: 2px solid #b1b4c1;
        padding: 15px 40px;
        border-radius: 6px;
        display: inline-block;
        font-weight: 500;
        -webkit-transition: .3s;
        transition: .3s;
        
    }

    a.rn-button-style--2.btn-solid, button.rn-button-style--2.btn-solid {
        border: 2px solid #e4ac16;
        background: #e4ac16;
        color: #fff;
        
    }

    .section-title {
        margin-top: -20px !important;
    }
    .mb--50 {
        margin-bottom: 50px;
    }

    .section-title h2.title {
        font-size: 60px !important;
        margin-bottom: 8px !important;
        
        font-weight: 700 !important;
        font-family: Poppins,sans-serif !important;
        line-height: 1.4074 !important;
        color: #1f1f25 !important;
        margin: 0 0 15px !important;
    }

    .section-title p {
        font-size: 20px;
        line-height: 30px;
        color: rgba(29,29,36,.75);
    }

    .contact-form--1 input:focus, .contact-form--1 textarea:focus {
        border-color: #e4ac16;
    }

    .thumbnail img {
        border-radius: 5px;
        box-shadow: 0 25px 65px rgba(0,0,0,.1);
    }
    

</style>

<style>
    

    .spin-animation {
        animation-name: spinner;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear; 
        /* transform: rotate(3deg); */
        /* transform: rotate(0.3rad);/ */
        /* transform: rotate(3grad); */ 
        /* transform: rotate(.03turn);  */
    }
    
    @-moz-keyframes spinner {
        from { -moz-transform: rotate(0deg); }
        to { -moz-transform: rotate(360deg); }
    }
    @-webkit-keyframes spinner {
        from { -webkit-transform: rotate(0deg); }
        to { -webkit-transform: rotate(360deg); }
    }
    @keyframes spinner {
        from {transform:rotate(0deg);}
        to {transform:rotate(360deg);}
    }
</style>